<!--
 * @Author: sunxj
 * @Date: 2021-11-16 15:02:15
 * @LastEditTime: 2021-11-22 15:00:46
 * @LastEditors: sunxj
 * @Description: add 鱼玩官网
-->
<template>
  <div class="flow">
    <page-header />
    <div class="header-top">
      <nav-list />
      <div class="header-text">
        <div class="header-title">
          鱼玩沙盒
        </div>
        <div class="header-sign">
          行业首创CPL游戏在线试玩，流量不外泄。
        </div>
      </div>
    </div>
    <section class="module-1">
      <div class="content">
        <h2 class="content-title">
          广告投放
        </h2>
        <ul class="slogan-list">
          <li>真流量</li>
          <li>精用户</li>
          <li>高转化</li>
          <li>好服务</li>
        </ul>
        <ul class="benefit-cells">
          <li class="benefit-cell">
            <i class="icon icon-1" />
            <h3 class="title">
              优质流量
            </h3>
            <p>
              针对媒体流量，多维度防作弊机型，筛选最优质媒体流量，保证流量转化真实有效
            </p>
          </li>
          <li class="benefit-cell">
            <i class="icon icon-2" />
            <h3 class="title">
              用户激励试玩
            </h3>
            <p>不同任务丰厚奖励</p>
            <p>更有新手礼包刺激用户试玩</p>
          </li>
          <li class="benefit-cell">
            <i class="icon icon-3" />
            <h3 class="title">
              留存活动丰富
            </h3>
            <p>定期活动推陈出新</p>
            <p>不同玩法带给用户新体验</p>
          </li>
          <li class="benefit-cell">
            <i class="icon icon-4" />
            <h3 class="title">
              专业服务
            </h3>
            <p>7*24全天候专业人员服务</p>
          </li>
        </ul>

        <h2 class="content-title">
          媒体投放
        </h2>
        <ul class="slogan-list">
          <li>免安装</li>
          <li>多变现</li>
          <li>高收益</li>
          <li>高升值</li>
        </ul>
        <ul class="benefit-cells">
          <li class="benefit-cell">
            <i class="icon icon-5" />
            <h3 class="title">
              无需安装
            </h3>
            <p>在线试玩，无需安装app</p>
            <p>防止流量外泄</p>
          </li>
          <li class="benefit-cell">
            <i class="icon icon-6" />
            <h3 class="title">
              用户激励试玩
            </h3>
            <p>100+款游戏，精准投放</p>
            <p>投其所好</p>
          </li>
          <li class="benefit-cell">
            <i class="icon icon-7" />
            <h3 class="title">
              留存活动丰富
            </h3>
            <p>cpl对接形式，可对接性强；用户激励实时发放，充分激发流量价值</p>
          </li>
          <li class="benefit-cell">
            <i class="icon icon-8" />
            <h3 class="title">
              专业服务
            </h3>
            <p>合适场景，有助于优化媒体自身产品留存及用户体验深度及留存</p>
          </li>
        </ul>
      </div>
    </section>
    <section class="module-2">
      <div class="content">
        <h2 class="content-title">
          鱼玩沙盒模式
        </h2>
        <ul class="process-cells">
          <template v-for="(item, index) in processList">
            <li
              :key="index"
              class="process-cell"
              :class="(index === processActive)? 'active' : ''"
            >
              <div
                class="process-cell_con"
                v-html="item.desc"
              />
            </li>
          </template>
          <!-- <li class="process-cell">
            <div class="process-cell_con">
              用户通过媒体平台找到鱼玩沙盒入口
            </div>
          </li>
          <li class="process-cell">
            <div class="process-cell_con">
              用户通过媒体平台找到鱼玩沙盒入口
            </div>
          </li>
          <li class="process-cell">
            <div class="process-cell_con">
              用户通过媒体平台找到鱼玩沙盒入口
            </div>
          </li> -->
        </ul>
        <img
          src="../img/yuwan/process-pic.png"
          style="width:100%"
        >
      </div>
    </section>
    <div class="footer-content">
      <page-footer />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
export default {
  components: {
    NavList: Header,
    PageHeader,
    PageFooter
  },
  data() {
    this.processList = [{
      desc: '用户通过媒体平台找到鱼玩沙盒入口'
    }, {
      desc: '用户进入鱼玩沙盒选择游戏进行试玩'
    }, {
      desc: '用户点击游戏<br>进行在线试玩'
    }, {
      desc: '用户完成任务<br>奖励自动发放'
    }]
    return {
      processActive: 0,
      timer: null
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.processActive >= 3) {
        this.processActive = 0
        return
      }
      this.processActive++
    }, 2000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.header-top{
  overflow: hidden;
  position: relative;
  // min-height: 285px;
  background: url('../img/yuwan/banner.png');
  //background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin-bottom: 0;
}
.header-text{
  // position: absolute;
  margin-top: 166px;
  margin-left: 260px;
  margin-bottom: 70px;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  //font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color:rgba(255,255,255,0.75)
}
.content{
  padding: 0 300px;
  
}
.footer-content{
  padding: 0 300px;
}
.module-1 {
  background-color: #fff;
  padding-bottom: 140px;
}
.content-title {
  font-size: 44px;
  font-weight: bold;
  color: #444;
  padding-top: 110px;
  margin-bottom: 130px;
  text-align: center;
  position: relative;
  line-height: 1;
  &::after {
    content: '';
    display: inline-block;
    width: 80px;
    height: 8px;
    background: linear-gradient(90deg, #FF7200, #FFA200);
    position: absolute;
    bottom: -48px;
    left: 50%;
    margin-left: -40px;
  }
}
.module-2 {
  background: linear-gradient(90deg, #FF7200, #FFA200);
  margin-bottom: 70px;
  .content-title {
    padding-top: 70px;
    color: #fff;
    &::after {
      background: #fff;
    }
  }
}
.slogan-list {
  font-size: 32px;
  color: #3F4346;
  display: flex;
  padding: 0 200px;
  justify-content: space-between;
  list-style: none;
  margin-top: 120px;
  li {
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      background: #FF8400;
      border-radius: 50%;
      position: absolute;
      left: -46px;
      top: 50%;
      margin-top: -8px;
    }
  }
}
.benefit-cells {
  display: flex;
  list-style: none;
  margin-top: 120px;
}
.benefit-cell {
  margin-left: 30px;
  // flex-basis: 25%;
  flex: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 14px 0px rgba(4, 0, 0, 0.06);
  padding: 80px 18px 18px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  &:first-child {
    margin-left: 0;
    text-align: center;
  }
  .icon {
    display: inline-block;
    background-image: url('~@/img/yuwan/icon-sprites.png');
    background-repeat: no-repeat;
    background-position: -136px -150px;
    width: 106px;
    height: 120px;
    position: absolute;
    top: -60px;
    left: 50%;
    margin-left: -53px;
    &.icon-1 {
      background-position: -136px -150px;
    }
    &.icon-2 {
      background-position: -10px -10px;
    }
    &.icon-3 {
      background-position: -262px -10px;
    }
    &.icon-4 {
      background-position: -136px -10px;
    }
    &.icon-5 {
      background-position: -262px -150px;
    }
    &.icon-6 {
      background-position: -10px -150px;
    }
    &.icon-7 {
      background-position: -388px -150px;
    }
    &.icon-8 {
      background-position: -388px -10px;
    }
  }
  
  .title {
    font-size: 28;
    font-weight: 500;
    color: #FF7800;
    // line-height: 2em;
    padding: 14px 0;
  }
  p {
    font-size: 18px;
    color: #777;
    line-height: 1.8;
  }
}
.process-cells {
  display: flex;
  list-style: none;
}
.process-cell {
  flex: 1;
  position: relative;
  padding: 0 4%;
  &::before {
    content: '';
    display: inline-block;
    width: 47px;
    height: 22px;
    background: url('~@/img/yuwan/arrow-icon.png') no-repeat 0 0;
    position: absolute;
    right: -22px;
    top: 50%;
    margin-top: -10px;
    opacity: .8;
  }
  &:last-child {
    &::before {
      content: none;
    }
  }
  &.active {
    .process-cell_con {
      background-color: #fff;
      color: #FF7800;
      &::after {
        border-top-color: #fff;
      }
    }
  }
}
.process-cell_con {
  background-color: #FFA85B;
  border-radius: 12px;
  position: relative;
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  padding: 12px;
  text-align: center;
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    font-size: 0;
    border: 10px solid transparent;
    border-left-width: 8px;
    border-right-width: 8px;
    border-top-color: #FFA85B;
    position: absolute;
    bottom: -19px;
    left: 50%;
    margin-left: -8px;
  }
  
}
@media screen and (max-width: 1440px) {
  .header{
    height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 100px;
  }
  .footer-content{
    padding: 0 100px;
  }
}

</style>